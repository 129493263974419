var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// @mui
import { styled, alpha } from '@mui/material/styles';
// ----------------------------------------------------------------------
export var StyledArrow = styled('span')(function (_a) {
    var _b;
    var arrow = _a.arrow, theme = _a.theme;
    var SIZE = 12;
    var POSITION = -(SIZE / 2);
    var borderStyle = "solid 1px ".concat(alpha(theme.palette.grey[500], 0.12));
    var topStyle = {
        borderRadius: '0 0 3px 0',
        top: POSITION,
        borderBottom: borderStyle,
        borderRight: borderStyle,
    };
    var bottomStyle = {
        borderRadius: '3px 0 0 0',
        bottom: POSITION,
        borderTop: borderStyle,
        borderLeft: borderStyle,
    };
    var leftStyle = {
        borderRadius: '0 3px 0 0',
        left: POSITION,
        borderTop: borderStyle,
        borderRight: borderStyle,
    };
    var rightStyle = {
        borderRadius: '0 0 0 3px',
        right: POSITION,
        borderBottom: borderStyle,
        borderLeft: borderStyle,
    };
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign((_b = { display: 'none' }, _b[theme.breakpoints.up('sm')] = {
        zIndex: 1,
        width: SIZE,
        height: SIZE,
        content: "''",
        display: 'block',
        position: 'absolute',
        transform: 'rotate(-135deg)',
        background: theme.palette.background.paper,
    }, _b), (arrow === 'top-left' && __assign(__assign({}, topStyle), { left: 20 }))), (arrow === 'top-center' && __assign(__assign({}, topStyle), { left: 0, right: 0, margin: 'auto' }))), (arrow === 'top-right' && __assign(__assign({}, topStyle), { right: 20 }))), (arrow === 'bottom-left' && __assign(__assign({}, bottomStyle), { left: 20 }))), (arrow === 'bottom-center' && __assign(__assign({}, bottomStyle), { left: 0, right: 0, margin: 'auto' }))), (arrow === 'bottom-right' && __assign(__assign({}, bottomStyle), { right: 20 }))), (arrow === 'left-top' && __assign(__assign({}, leftStyle), { top: 20 }))), (arrow === 'left-center' && __assign(__assign({}, leftStyle), { top: 0, bottom: 0, margin: 'auto' }))), (arrow === 'left-bottom' && __assign(__assign({}, leftStyle), { bottom: 20 }))), (arrow === 'right-top' && __assign(__assign({}, rightStyle), { top: 20 }))), (arrow === 'right-center' && __assign(__assign({}, rightStyle), { top: 0, bottom: 0, margin: 'auto' }))), (arrow === 'right-bottom' && __assign(__assign({}, rightStyle), { bottom: 20 })));
});
